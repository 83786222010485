import { graphql } from 'gatsby';
import { string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import parse from 'html-react-parser';

import { Content } from '../../common/content';
import './textblock.scss';

const bem = new BEMHelper({
  name: 'content-textblock',
});

export const Textblock = ({
  id,
  headline,
  text,
  footnotes,
  ratio
}) => (
  <Content id={id} {...bem(null, { 'with-headline': headline }, ('ratio-' + ratio) )}>
    {headline && <h2 {...bem('headline')}>{headline}</h2>}
    <div {...bem('wrapper')}>
      <div {...bem('text')}>{parse(text)}</div>
      {footnotes && (
        <small {...bem('footnotes')}>
          {parse(footnotes)}
        </small>
      )}
    </div>
  </Content>
);

Textblock.propTypes = {
  id: string.isRequired,
  headline: string,
  text: string.isRequired,
  footnotes: string,
  ratio: string
};

Textblock.defaultProps = {
  headline: undefined,
  footnotes: undefined,
  ratio: undefined
};

export const fragment = graphql`
  fragment TextblockContentFragment on CraftGQL_pageContents_textblock_BlockType {
    id
    headline
    text
    footnotes
    ratio
  }
`;
